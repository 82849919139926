
export default build =>
    build.query({
        tagTypes: ['allImpYoutubeVideos'],
        keepUnusedDataFor: 1,
        query(payload) {
            //console.log("payload",payload)            
            return {
                url: `/youtube/getImpYoutubeVideos?page_index=${payload.pageIndex}&page_size=${payload.pageSize}&imp_video_category=${payload.imp_video_category}`,                         
                method: 'GET',
              
            };
           
        },
       providesTags: ['allImpYoutubeVideos']
    })
