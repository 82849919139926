export const Config = {
   
    // API_URL_LOCAL: 'http://localhost:8001/api/',
    // API_FILE: 'http://localhost:8001/api',
  // API_CLOUDFRONT:'https://d2p2mde5k2x6t0.cloudfront.net',//From My Account

     API_CLOUDFRONT:'https://d30vp2pixk7dq1.cloudfront.net',
  
    API_URL_LOCAL: 'https://backend.drsandeeppatil.com/api/',
     API_FILE: 'https://backend.drsandeeppatil.com/api/'

     
    // API_FILE: 'https://backend.drsandeeppatil.com'
  
  }
  