import { api } from '../../api'
import addImpYoutubeVideos from './addImpYoutubeVideos'
import addYoutubeMoodTestVideos from './addYoutubeMoodTestVideos'

import addYoutubeVideos from './addYoutubeVideos'
import deleteImpVideo from './deleteImpVideo'
import deleteYoutubeVideo from './deleteYoutubeVideo'
import getAllMoodTestVideos from './getAllMoodTestVideos'
import getAllYoutubeVideos from './getAllYoutubeVideos'
import getImpYoutubeVideos from './getImpYoutubeVideos'

export const youtubeVideosApi = api.injectEndpoints({
    endpoints: build => ({
        AddYoutubeVideos: addYoutubeVideos(build),
        AddYoutubeMoodTestVideos: addYoutubeMoodTestVideos(build),
        AddImpYoutubeVideos:addImpYoutubeVideos(build),

        GetAllYoutubeVideos:getAllYoutubeVideos(build)     ,
        GetAllMoodTestVideos:getAllMoodTestVideos(build)     ,

        DeleteYoutubeVideo:   deleteYoutubeVideo(build) ,
        DeleteImpYoutubeVideo:deleteImpVideo(build),
        GetImpYoutubeVideos:getImpYoutubeVideos(build)
       
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddYoutubeVideosMutation, 
    useGetAllYoutubeVideosQuery,
    useGetAllMoodTestVideosQuery,
    useDeleteYoutubeVideoMutation,
    useAddYoutubeMoodTestVideosMutation,
    useAddImpYoutubeVideosMutation,
    useGetImpYoutubeVideosQuery,
    useDeleteImpYoutubeVideoMutation
  
} = youtubeVideosApi
