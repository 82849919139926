import React, { useState,useEffect } from 'react'
import {Box, Grid, Paper,Container, Tooltip } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";

import { Config } from '../Config'
import { useCreateBookMutation, useDeleteBookMutation, useGetAllBooksQuery } from '../Services/modules/books'
import { useCreateTestimonialMutation, useDeleteTestimonialMutation, useGetAllTestimonialsQuery, useUpdateTestimonialMutation } from '../Services/modules/testimonials'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';

function Testimonials() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
const{data:testimonialsData=[],isSuccess:isTestimonialsSuccess,isLoading:isTestimonialsLoading,error:isTestimonialsError}=useGetAllTestimonialsQuery()
  const [CreateTestimonial, { data, isSuccess, isLoading, isError, error }] = useCreateTestimonialMutation();
  const [UpdateTestimonial, { data:updateData, isSuccess:updateSuccess, isLoading:updateLoading, isError:updateIsError, error:updateError }] = useUpdateTestimonialMutation();

  const [DeleteTestimonial, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError}] = useDeleteTestimonialMutation();
 
  const [testimonial_id, setTestimonialId] = useState('');
  const [is_update, setIsUpdate] = useState(false);

  
  const [review_by, setReviewBy] = useState('');

    const [testimonial_type, setTestimonialType] = useState('');
    const [long_text, setLongText] = useState('');
    const[long_text_disabled,setLongTextDisabled]=useState(true)
    const [small_text, setSmallText] = useState('');
    const[small_text_disabled,setSmallTextDisabled]=useState(true)
    const [video_link, setVideoLink] = useState('');
    const[video_link_disabled,setVideoLinkDisabled]=useState(true)
    const [for_course, setForCourse] = useState('');
  const [file, setFile] = useState([]);   
  const [stuentLocationType, setLocationType] = useState('');

    const[allTestimonials,setAllTestimonials]=useState([])
    const[showDialogDelete,setShowDialogDelete]=useState(false)
    const[showDialogDetails,setShowDialogDetails]=useState(false)
    const[viewTestimonialsDetails,setViewTestimonialsDetails]=useState({})

    useEffect(()=>{
        if(testimonial_type=="video")
        {
            setVideoLinkDisabled(false)
            setLongText("")
            setLongTextDisabled(true)
            setSmallText("")
            setSmallTextDisabled(true)
        }
        else if(testimonial_type=="text")
        {
            setVideoLink("")
            setVideoLinkDisabled(true)
            setLongTextDisabled(false)
            setSmallTextDisabled(false)

        }
      },[testimonial_type])
    useEffect(()=>{
      console.log("testimonials data",testimonialsData)
    if(isTestimonialsSuccess)
    {
    
        setAllTestimonials(testimonialsData.data)
    }
    },[testimonialsData])
useEffect(()=>{
 
if(isSuccess)
{
  toast.success("Testimonial created successfully")
 
}
},[data])
useEffect(()=>{
 setTestimonialId("")
 setIsUpdate(false)
 setLongText("")
 setSmallText("")
 setTestimonialType("")
 setVideoLink("")
 setForCourse("")
 setReviewBy("")
  if(updateSuccess)
  {
    toast.success("Testimonial updated successfully")
   
  }
  },[updateData])
useEffect(()=>{
    setShowDialogDelete(false)
if(deleteSuccess)
{
  toast.success("Testimonial Deleted successfully")
 
}
},[deleteData])
const columns = [   
    { 
        key: "review_by", 
        title: "User", 
        dataIndex: "review_by", 
        },  
    { 
    key: "testimonial_type", 
    title: "Type", 
    dataIndex: "testimonial_type", 
    }, 
    { 
        key: "for_course", 
        title: "For Course", 
        dataIndex: "for_course", 
        }, 
    
    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">  
        <a onClick={()=>showDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 
        <a onClick={()=>deleteTestimonialConfirm(record)} style={{color:"green"}}>
          
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
          <a onClick={()=>editTestimonials(record)} style={{color:"green"}}>
          
          <EditIcon style={{fontSize:'medium'}}/> 
          </a>   
         
        </Space>
      ),
    },
    
    ] 
    const testimonial_type_list = [
        { key: "video", value: "video" },
        { key: "text", value: "text" },      
       
      ];
      const studentLocationTypeList = [
        { key: "national", value: "national" },
        { key: "international", value: "international" },      
       
      ];
      const course_list = [
        { key: "residential", value: "Residential" },
        { key: "zoom_live", value: "Zoom Live" },      
        { key: "overall", value: "overall" },      
       
      ];
    const showDetails=(record)=>{
        setViewTestimonialsDetails(record)
        setShowDialogDetails(true);
       }
    const deleteTestimonialConfirm=(record)=>{
        console.log("Notes id",record._id)
        setTestimonialId(record._id)
        setShowDialogDelete(true)
        }
        const editTestimonials=(record)=>{
          console.log("Notes id",record._id)
          setTestimonialId(record._id)
          setIsUpdate(true)
         // setShowDialogDelete(true)
          setLongText(record.long_text)
          setSmallText(record.small_text)
          setTestimonialType(record.testimonial_type)
          setVideoLink(record.video_link)
          setForCourse(record.for_course)
          setReviewBy(record.review_by)

          // const [testimonial_type, setTestimonialType] = useState('');
          // const [long_text, setLongText] = useState('');
          // const[long_text_disabled,setLongTextDisabled]=useState(true)
          // const [small_text, setSmallText] = useState('');
          // const[small_text_disabled,setSmallTextDisabled]=useState(true)
          // const [video_link, setVideoLink] = useState('');
          // const[video_link_disabled,setVideoLinkDisabled]=useState(true)
          // const [for_course, setForCourse] = useState('');
          }
const deleteTestimonial=()=>{
console.log("Notes id",testimonial_id)
DeleteTestimonial({id:testimonial_id})
}
const closeConfirmAlert=()=>{
  
    setShowDialogDelete(false)

   }
   const closeDetailDialog=()=>{
  
    setShowDialogDetails(false)

   }
   
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Check if the selected file is a PDF
        if (selectedFile && selectedFile.type.startsWith('image/')) {
          setFile(selectedFile);
        } else {
          alert('Please select a Image file.');
        }
      };
    
    function onSubmit (){    
        console.log("notes file",file)
        const form = new FormData(); 
        form.append('review_by',review_by)
        form.append('small_text',small_text);
        form.append('long_text',long_text);    
        form.append('video_link',video_link);   
        form.append('testimonial_type',testimonial_type);    
        form.append('for_course',for_course);  
        form.append("testimonial_id",testimonial_id)  
        form.append("stuentLocationType",stuentLocationType)  


        form.append('photo_url',file); 
if(is_update)
{
  UpdateTestimonial(form)
}
else
{
  CreateTestimonial(form)        

}
     }
    const TestimonialsScreen=()=>{
        return(
          <>
           <Dialog
        open={showDialogDelete}
        onClose={closeConfirmAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmAlert}>No</Button>
          <Button onClick={deleteTestimonial} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialogDetails}
        onClose={closeDetailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Testimonials Details"}
        </DialogTitle>
        <DialogContent>
        <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 5
        }}
        alt="Image Not Found"
        src={`https://backend.drsandeeppatil.com/TestimonialPhotos/${viewTestimonialsDetails.photo_url}`}

        // src={`http://localhost:8001/TestimonialPhotos/${viewTestimonialsDetails.photo_url}`}
    //   src={`https://backend.sanglishikshansanstha.com/StaffPhoto/${viewStaffDetails.photo}`}

       
      />
        <Grid item xs={12} md={12} lg={12}>
         Testimonial Type: {viewTestimonialsDetails.testimonial_type}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         For Course: {viewTestimonialsDetails.for_course}
         </Grid>
        <Grid item xs={12} md={12} lg={12}>
         Small Text: {viewTestimonialsDetails.small_text}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Long Text: {viewTestimonialsDetails.long_text}
         </Grid>
         <Grid item xs={12} md={12} lg={12}>
         Location type: {viewTestimonialsDetails.stuentLocationType}
         </Grid>
         
         <Grid item xs={12} md={12} lg={12}>
         <iframe width="560" height="315" 
        src={`https://www.youtube.com/embed/${viewTestimonialsDetails.video_link}`}                    
        frameborder="0" allowfullscreen></iframe>  
         </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailDialog}>Close</Button>
        
        </DialogActions>
      </Dialog>
           <ToastContainer />
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div> 
        <TextBox
               values={testimonial_type_list}
               label={"Type*"}
               id={"testimonial_type"}
               name={"testimonial_type"}
              isSelect={true}
             autoFocus={true}                         
              value={testimonial_type} 
              onChange={(e)=>setTestimonialType(e.target.value)}

              />    
               <TextBox
               values={course_list}
               label={"For Course*"}
               id={"for_course"}
               name={"for_course"}
              isSelect={true}
              value={for_course} 
              onChange={(e)=>setForCourse(e.target.value)}
              />        
          <TextBox             
             label={"Small Text"}
             id={"small_text"}
             name={"small_text"}
             disabled={small_text_disabled}
             autoFocus={false}                         
            value={small_text}  
            isSelect={false}      
            onChange={(e)=>setSmallText(e.target.value)}
            />        
             <TextBox             
             label={"Long Text"}
             id={"long_text"}
             disabled={long_text_disabled}
             name={"long_text"}
             autoFocus={false}                         
            value={long_text}  
            isSelect={false}      
            onChange={(e)=>setLongText(e.target.value)}
            />    
             <TextBox             
             label={"Video Link"}
             id={"video_link"}
             name={"video_link"}
             autoFocus={false}                         
            value={video_link}  
            isSelect={false}    
            disabled={video_link_disabled}  
            onChange={(e)=>setVideoLink(e.target.value)}
            />   
              <TextBox             
             label={"User Name"}
             id={"review_by"}
            
             name={"review_by"}
             autoFocus={false}                         
            value={review_by}  
            isSelect={false}      
            onChange={(e)=>setReviewBy(e.target.value)}
            />  

             <TextBox
               values={studentLocationTypeList}
               label={"Location*"}
               id={"studentLocationType"}
               name={"studentLocationType"}
              isSelect={true}
             autoFocus={true}   
             value={stuentLocationType} 

             // value={studentLocationTypeList} 
              onChange={(e)=>setLocationType(e.target.value)}

              />                     
        </div>
        <div>         
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />
        </div>

       
            <SubmitButton 
              caption={is_update?"Update":"Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allTestimonials}/>
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {TestimonialsScreen()}
   </AdminTemplate>
  )
}

export default Testimonials